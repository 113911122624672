<template>
  <div class="news-container">
    <div class="news-list">
      <template v-for="(item, index) in news">
        <!-- type: 1 站内新闻 -->
        <router-link
          v-if="item.type === 1"
          :key="index"
          target="_blank"
          class="news-item"
          :to="{ name: 'NewsDetail', params: { id: item.id }}"
        >
          <!--<a class="news-item" :href="'detail.html#/' + item.id" target="_blank" :key="index" v-if="item.type === 1">-->
          <div class="news-item-img">
            <div>
              <img
                :src="item.cover"
                alt=""
              >
            </div>
          </div>
          <div class="new-item-info">
            <span class="info-title">{{ item.title }}</span>
            <p class="info-date">
              <span />
              <span>{{ item.pub_date }}</span>
            </p>
            <span class="info-desc">{{ item.digest }}</span>
            <span class="info-read">阅读全文</span>
          </div>
        <!--</a>-->
        </router-link>
        <!-- type: 2 站外新闻 -->
        <a
          v-if="item.type === 2"
          :key="index"
          class="news-item"
          :href="item.link"
          target="_blank"
        >
          <div class="news-item-img">
            <div>
              <img
                :src="item.cover"
                alt=""
              >
            </div>
          </div>
          <div class="new-item-info">
            <span class="info-title">{{ item.title }}</span>
            <p class="info-date">
              <span />
              <span>{{ item.created_at }}</span>
            </p>
            <span class="info-desc">{{ item.digest }}</span>
            <span class="info-read">阅读全文</span>
          </div>
        </a>
      </template>
    </div>
    <!-- 分页 -->
    <div class="news-pages">
      <ul class="news-pages-list">
        <li
          :class="[page === 1 ? 'disabled' : 'active']"
          @click="turnPage('first')"
        >
          第一页
        </li>
        <li
          :class="[page > 1 ? 'active' : 'disabled']"
          @click="turnPage('prev')"
        >
          上一页
        </li>
        <li
          :class="[lastPage > page ? 'active' : 'disabled']"
          @click="turnPage('next')"
        >
          下一页
        </li>
        <li
          :class="[lastPage > page ? 'active' : 'disabled']"
          @click="turnPage('last')"
        >
          最末页
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      // 新闻列表
      news: [],
      // 当前页面
      page: 1,
      // 最后一页
      lastPage: 1,
      // 设置自适应图片的宽度和高度
      imgWidth: 0
    }
  },
  mounted () {
    this.getNews()
  },
  methods: {
    // 翻页
    turnPage (page) {
      // 第一页
      if (page === 'first') {
        if (this.page > 1) {
          this.getNews('/api/news?page=1')
        }
      }
      // 最后一页
      if (page === 'last') {
        if (this.lastPage > this.page) {
          this.getNews(`/api/news?page=${this.lastPage}`)
        }
      }
      // 上一页
      if (page === 'prev') {
        if (this.page > 1) {
          this.getNews(`/api/news?page=${this.page - 1}`)
        }
      }
      // 下一页
      if (page === 'next') {
        if (this.lastPage > this.page) {
          this.getNews(`/api/news?page=${this.page + 1}`)
        }
      }
    },
    // 获取新闻列表
    getNews (url) {
      this.$store.dispatch('getNews', url).then(res => {
        if (url) {
          this.news = []
        }
        if (res.data.length > 0) {
          res.data.forEach(item => {
            this.news.push(item)
          })
        }
        // 处理页面
        this.page = res.current_page
        // 最后一页
        this.lastPage = res.last_page
      })
    }
  }
}
</script>
<style lang="less" scoped>
.news-container {
  background: #fafafa;
}
.news-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.news-item {
  background: #fff;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  position: relative;
  .new-item-info {
    .info-title {
      display: block;
      text-align: left;
      color: #3b3b3b;
      font-size: 17px;
      letter-spacing: 1px;
    }
    .info-date {
      display: flex;
      align-items: center;
      margin-top: 5px;
      span {
        &:first-child {
          display: inline-block;
          width: 12px;
          height: 12px;
          background: #e44f3c;
        }
        &:last-child {
          font-size: 14px;
          letter-spacing: 1px;
          margin-left: 8px;
          color: #3b3b3b;
        }
      }
    }
    .info-desc {
      display: block;
      color: #777;
      letter-spacing: 1px;
      font-size: 15px;
      text-align: left;
      margin-top: -8px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .info-read {
      position: absolute;
      color: #3b3b3b;
      letter-spacing: 2px;
    }
  }
}
/* 分页 */
.news-pages {
  .news-pages-list {
    display: flex;
    align-items: center;
    li {
      height: 40px;
      line-height: 30px;
      border-left: 1px solid #ccd4d7;
      border-top: 1px solid #ccd4d7;
      border-bottom: 1px solid #ccd4d7;
      font-size: 15px;
      padding: 5px 18px;
      box-sizing: border-box;
    }
    /* 禁用翻页 */
    .disabled {
      cursor: not-allowed;
      color: #777;
    }
    /* 可以翻页 */
    .active {
      cursor: pointer;
      color: #297ed3;
      background: #fff;
      &:hover {
        background: #eee;
      }
    }
    .current {
      background: #297ed3;
      color: #fff;
    }
  }
}
/* -----------适应pc端(大屏幕)--------------- */
@media screen and (min-width: 1160px) {
  .news-list {
    width: 1200px;
    margin: 40px auto 0;
    .news-item {
      width: 49%;
      margin-bottom: 20px;
      padding: 30px 20px;
      .news-item-img {
        width: 199px;
        height: 199px;
        div {
          width: 199px;
          height: 199px;
          img {
            width: 199px;
            height: 199px;
          }
        }
      }
      .new-item-info {
        padding-left: 20px;
        .info-title {
          line-height: 27px;
        }
        .info-desc {
          line-height: 23px;
          -webkit-line-clamp: 4;
        }
        .info-read {
          font-size: 16px;
          bottom: 20px;
          right: 25px;
        }
      }
    }
  }
  /* 分页 */
  .news-pages {
    width: 1200px;
    margin: 0 auto;
    padding-right: 30px;
    box-sizing: border-box;
    .news-pages-list {
      justify-content: flex-end;
      border-right: 1px solid #ccd4d7;
    }
  }
}
/* -----------适应pc端(中屏幕)--------------- */
@media screen and (min-width: 800px) and (max-width: 1160px) {
  .news-list {
    width: 100%;
    .news-item {
      width: 49%;
      margin-bottom: 15px;
      padding: 15px 12px;
      .news-item-img {
        width: 139px;
        height: 139px;
        div {
          width: 139px;
          height: 139px;
          img {
            width: 139px;
            height: 139px;
          }
        }
      }
      .new-item-info {
        padding-left: 10px;
        overflow: hidden;
        .info-title {
          line-height: 27px;
          width: 90%;
          box-sizing: border-box;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .info-desc {
          line-height: 23px;
          -webkit-line-clamp: 2;
        }
        .info-read {
          font-size: 16px;
          bottom: 20px;
          right: 25px;
        }
      }
    }
  }
  /* 分页 */
  .news-pages {
    width: 100%;
    .news-pages-list {
      justify-content: center;
      padding-left: 0;
      li {
        &:last-child {
          border-right: 1px solid #ccd4d7;
        }
      }
    }
  }
}
/* -------------适应移动端---------------- */
@media screen and (max-width: 800px) {
  .news-list {
    width: 100%;
    .news-item {
      width: 100%;
      margin-bottom: 15px;
      padding: 15px;
      .news-item-img {
        width: 100px;
        height: 100px;
        div {
          width: 100px;
          height: 100%;
          img {
            width: 100px;
            height: 100%;
          }
        }
      }
      .new-item-info {
        padding-left: 10px;
        box-sizing: border-box;
        overflow: hidden;
        .info-title {
          line-height: 22px;
          width: 85%;
          box-sizing: border-box;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .info-date {
          margin-bottom: 12px;
        }
        .info-desc {
          line-height: 20px;
          -webkit-line-clamp: 2;
        }
        .info-read {
          font-size: 15px;
          bottom: 4px;
          right: 15px;
        }
      }
    }
  }
  /* 分页 */
  .news-pages {
    width: 100%;
    .news-pages-list {
      justify-content: center;
      padding-left: 0;
      li {
        &:last-child {
          border-right: 1px solid #ccd4d7;
        }
      }
    }
  }
}
</style>
